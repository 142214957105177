<template>
  <div id="app">
    <!-- start preloader -->
    <div class="preloader align-items-center justify-content-center">
        <span class="loader"></span>
    </div>
    <!-- end preloader -->

    <!-- Start Custom Cursor -->
    <div class="mouse-follower">
        <span class="cursor-outline"></span>
        <span class="cursor-dot"></span>
    </div>
    <!-- End Custom Cursor -->

    <Header @scroll-to-anchor="scrollToAnchor" />

    <Sidebar @scroll-to-anchor="scrollToAnchor" />    
    
    <router-view ref="routerView" />

    <Footer />

  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default{
  components: { Sidebar, Header, Footer },
  methods: {
    scrollToAnchor(anchorId) {
      // Pass the anchor ID to the content component
      this.$refs.routerView.scrollToSection(anchorId);
    },
  },
 
}
</script>