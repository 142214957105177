<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">Laravel Backend Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>Laravel Backend Developer</span></h2>
                              <p>Are you a skilled Laravel developer with a passion for building robust and scalable web applications? We are seeking a talented Laravel Backend Developer to join our dynamic team. As a Laravel expert, you will be responsible for designing and implementing server-side logic, collaborating with the frontend team, and ensuring seamless integration with our databases and APIs. If you thrive in a fast-paced environment and love crafting efficient and maintainable code, we'd love to hear from you!</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Develop and maintain server-side applications using Laravel framework.</li>
                                  <li>Collaborate with the frontend team to integrate user-facing elements with server-side logic.</li>
                                  <li>Design and optimize databases, ensuring high performance and data integrity.</li>
                                  <li>Implement RESTful APIs for seamless communication with other services.</li>
                                  <li>Write clean, efficient, and testable code following best practices and coding standards.</li>
                                  <li>Troubleshoot and resolve backend issues, ensuring application stability.</li>
                                  <li>Participate in code reviews and provide constructive feedback to team members.</li>
                                  <li>Stay up-to-date with the latest industry trends and best practices in backend development.</li>                               
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>At least more than one years of experience as a Laravel Backend Developer or similar role.</li> 
                                  <li>Proficiency in PHP, MySQL, and RESTful API development.</li> 
                                  <li>Strong understanding of MVC architecture and Laravel best practices.</li> 
                                  <li>Experience with version control systems like Git.</li> 
                                  <li>Familiarity with frontend technologies such as HTML, CSS, and JavaScript.</li> 
                                  <li>Solid understanding of database design and optimization techniques.</li> 
                                  <li>Ability to work collaboratively in a team environment and effectively communicate ideas.</li> 
                                  <li>Problem-solving skills and a proactive approach to challenges.</li> 
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="Laravel Backend Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LarvelBackendDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


