<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">AI Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>AI Developer</span></h2>
                              <p>We are seeking a skilled and enthusiastic AI Developer with expertise in building artificial intelligence solutions on the AWS platform. As an AI Developer, you will be responsible for designing, developing, and deploying AI models and applications using AWS AI/ML services. Join our team and contribute to the development of cutting-edge AI solutions that drive innovation and add value to our products and services.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Collaborate with data scientists and stakeholders to understand business requirements and design AI solutions that address complex challenges.</li>
                                  <li>Develop machine learning models using AWS AI/ML services, such as Amazon SageMaker, Amazon Rekognition, Amazon Comprehend, etc., to derive insights and predictions from data.</li>
                                  <li>Implement natural language processing (NLP) and computer vision algorithms to enable advanced AI functionalities.</li>
                                  <li>Leverage AWS infrastructure to scale AI models and ensure high performance and reliability.</li>
                                  <li>Optimize and fine-tune AI models to achieve the best possible accuracy and efficiency.</li>
                                  <li>Work closely with DevOps and Cloud Engineers to deploy AI solutions into production environments.</li>
                                  <li>Conduct thorough testing and validation of AI models to ensure accuracy and reliability.</li>
                                  <li>Stay updated with the latest advancements in AI and AWS services, and proactively suggest improvements and optimizations.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Proven experience as an AI Developer, with a focus on building AI solutions on the AWS platform.</li>
                                  <li>In-depth knowledge of machine learning algorithms and techniques for supervised and unsupervised learning.</li>
                                  <li>Familiarity with AWS AI/ML services, such as Amazon SageMaker, Amazon Rekognition, Amazon Comprehend, etc.</li>
                                  <li>Strong proficiency in programming languages such as Python, and experience with libraries like TensorFlow or PyTorch for AI model development.</li>
                                  <li>Understanding of natural language processing (NLP) and computer vision concepts for AI application development.</li>
                                  <li>Experience in handling large datasets and data preprocessing for AI training and inference.</li>
                                  <li>Solid understanding of cloud computing principles and hands-on experience with AWS services.</li>
                                  <li>Strong problem-solving and analytical skills to tackle complex AI challenges.</li>
                                  <li>Knowledge of version control systems like Git for collaborative development and code management.</li>
                                  <li>Good communication skills and the ability to work effectively in a team-oriented, agile development environment.</li>
                                  <li>Experience with AI model deployment and integration into production systems is a plus.</li>
                                  <li>Knowledge of big data technologies and distributed computing is an advantage.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="AI Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


