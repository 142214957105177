import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companyName: 'Codesquad Devs PLT',
    country: 'Malaysia',
    address: '2nd Floor, Lot 3299, Jalan Canna, TT3 Commercial Centre, 93350 Kuching, Sarawak',
    email: 'warrence@codesquad.com.my',
    phoneNumber: '(6012) 899-0010',
    workingHours: 'Mon-Fri: 09:00-18:00',
    fb: 'https://www.facebook.com/codesquaddevs',
    ig: 'https://www.instagram.com/codesquaddevs/',
    linkedin: 'https://linkedin.com/company/codesquaddevs',
    twitter: 'https://twitter.com/codesquaddevs',    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
