<template>
  <div class="hello">
    <!-- header-section start -->
    <header class="header-section header-menu">
        <nav class="navbar w-100 flex-nowrap px-2 py-6 ps-2 ps-xl-10 ps-xxl-10 navbar-expand-xl">
            <div class="sidebar-close mobile-menu">
                <button class="d-center d-grid d-xl-none">
                    <i class="material-symbols-outlined mat-icon fs-four"> menu_open </i>
                    <span class="fs-six">MENU</span>
                </button>
            </div>
            <a href="index.html" class="navbar-brand ms-4 ms-xxl-15 d-flex align-items-center gap-2">
                <img src="assets/images/fav.png" class="logo ml-sm-4" alt="logo">
                <img src="assets/images/logo-text.png" class="logo-text d-xxl-block" alt="logo-text">
            </a>
            <div class="collapse navbar-collapse d-flex gap-10 w-100 justify-content-end px-8 pe-2" id="navbar-content">
                <div class="contact-info ms-xl-0 ms-xxl-5 d-none d-sm-flex align-items-center gap-2">
                    <i class="material-symbols-outlined mat-icon"> smartphone </i>
                    <span>(6012) 899-0010</span>
                </div>
                <ul class="navbar-nav d-xl-flex d-none gap-3 py-4 py-lg-0 m-auto pe-20 align-self-center">
                    <li class="dropdown show-dropdown">
                        <router-link to="/" exact><button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav">Home</button></router-link>
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                          class="dropdown-nav" @click="scrollToAnchor('whatWeDo')">What We Do</button>
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav" @click="scrollToAnchor('applications')">Our Projects</button>                        
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav" @click="scrollToAnchor('workspace')">Our Workspace</button>                        
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav" @click="scrollToAnchor('jobs')">Join Us</button>                        
                    </li>
                    <li>
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav" @click="scrollToAnchor('contactUs')">Contact Us</button>
                    </li>
                </ul>
                
            </div>
        </nav>
    </header>
    <!-- header-section end -->
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  methods: {
    scrollToAnchor(anchorId) {
      if(this.$route.path !== '/'){
        this.$router.push({ name: 'home', params: {anchorId: anchorId} });
      }else{
        this.$emit('scroll-to-anchor', anchorId);
      }

    },
  },
}
</script>
