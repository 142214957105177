<template>
  <div class="apply-area text-center py-15 px-8">
      <div class="icon-box mb-6 mb-sm-10 d-inline-flex d-center">
          <i class="material-symbols-outlined fs-one"> {{ iconName }} </i>
      </div>
      <div class="section-text">
          <h2 class="visible-slowly-bottom mb-3">Apply for the position now!</h2>
          <span>Join a growing team in a dynamic environment.</span>          
      </div>                          
      <b-form> 
              <b-form-input
                v-model="applicant.email"
                type="text"
                placeholder="Email"
                required                
              >              
              </b-form-input>              
              <div v-if="$v.applicant.email.$dirty  && !$v.applicant.email.required"  class="text-danger">Email is required.</div>
              <div v-else-if="$v.applicant.email.$dirty  && !$v.applicant.email.email"  class="text-danger">Invalid email format.</div>
              <b-form-input
                v-model="applicant.name"
                type="text"
                placeholder="Full Name"
                required
                class="mt-2"
              ></b-form-input>
              <div v-if="$v.applicant.name.$dirty  && !$v.applicant.name.required"  class="text-danger">Name is required.</div>
              <b-form-input
                v-model="applicant.contactNumber"
                type="text"
                placeholder="Contact Number"
                required
                class="mt-2"
              ></b-form-input>              
              <div v-if="$v.applicant.contactNumber.$dirty  && !$v.applicant.contactNumber.required"  class="text-danger">Contact number is required.</div>
              <b-form-textarea
                v-model="applicant.introduction"
                placeholder="Please express your interest here..."
                required
                class="mt-2"
                rows="10"
              ></b-form-textarea>
              <div v-if="$v.applicant.introduction.$dirty  && !$v.applicant.introduction.required"  class="text-danger">Please express your interest.</div>
              <b-form-file
                :state="Boolean(applicant.cv_file)"
                placeholder="Upload your CV here"
                drop-placeholder="Drop file here..."
                class="mt-2 text-left"
                ref="fileInput"
                @change="handleFileChange"
              ></b-form-file>
              <div v-if="$v.applicant.cv_file.$dirty  && !$v.applicant.cv_file.required"  class="text-danger">Please upload your CV.</div>
          </b-form>
      <div class="btn-area">
          <div class="btn-area mt-5 mt-sm-8 flex-wrap gap-6 d-center alt-bg">
              <b-btn @click="applyNow()" variant="primary" :disabled="isLoading">
              <span v-if="isLoading" class="spinner-border spinner-border-sm text-center" role="status" aria-hidden="true"></span>
                  Apply Now
              </b-btn>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import { required, email } from 'vuelidate/lib/validators';



export default {
  name: 'ApplyNow',
  props: {
    job: String
  },  
  validations: {
    applicant: {
      name: { required },      
      email: { required, email },
      contactNumber: { required },
      introduction: { required },
      cv_file: { required }
    },
    form: ['applicant']
  },
  mounted(){    
    this.applicant.job = this.job
  },
  computed: {
    iconName() {
      if(this.job == 'Laravel Backend Developer' || this.job == 'Lambda API Developer'){
        return 'data_object'      
      }

      if(this.job == 'Vue.js Frontend Developer'){
        return 'code'
      }

      if(this.job == 'Mobile App Developer'){
        return 'developer_mode'
      }

      if(this.job == 'AI Developer'){
        return 'psychology'
      }

      if(this.job == 'IoT Developer'){
        return 'settings_remote'
      }

      if(this.job == 'UI/UX Designer'){
        return 'draw'
      }

      if(this.job == 'AWS Engineer'){
        return 'cloud'
      }


      return ''
    }
  },
  data:() => ({
    isLoading: false,
    applicant: {
      job: null,
      email: null,
      name: null,
      contactNumber: null,
      introduction: null,
      cv_file: null
    }
  }),
  methods:{
    handleFileChange(event) {
      const file = event.target.files

      if (!file) {
        return;
      }

      const fileSizeLimit = 2 * 1024 * 1024;

      if (file[0].size > fileSizeLimit) {
        Swal.fire({
          icon: 'error',
          title: 'File Size Exceeded!',
          text: 'File size exceeds the limit (2 MB). Please choose a smaller file.',
          showConfirmButton: true, // Hide the "OK" button
        });
        event.target.value = ''; // Reset the file input
        return;
      }else{
        this.applicant.cv_file = file
      }

    },
    async applyNow(){
      this.$v.form.$touch()
      if (!this.$v.applicant.$invalid) {
        const formData = new FormData();
        formData.append('job', this.applicant.job);
        formData.append('name', this.applicant.name);
        formData.append('email', this.applicant.email);
        formData.append('contactNumber', this.applicant.contactNumber);
        formData.append('introduction', this.applicant.introduction);

        // Append each file to the form data
        if (this.applicant.cv_file) {
          for (let i = 0; i < this.applicant.cv_file.length; i++) {
            formData.append('cv_files', this.applicant.cv_file[i]);
          }
        }

        this.isLoading = true

        // Send the form data to your Lambda function
          await axios.post('https://qxgn47s2zi.execute-api.ap-southeast-1.amazonaws.com/default/codesquadWebsiteApplyJobEmail', formData, 
          { 
            withCredentials: false, 
            headers: {
              'Content-Type': 'multipart/form-data', // Set the correct content type
            },
          }).then(response => {
            this.isLoading = false
            if(response.status == 200){
              Swal.fire({
                  icon: 'success',
                  title: 'Thank You!',
                  text: 'Your job application has been submitted successfully. We will contact you after reviewing your application.',
                  timerProgressBar: true, // Show progress bar for timer
                  showConfirmButton: true, // Hide the "OK" button
                });
            }
          }).catch (error=>{
            this.isLoading = false
            if(error){
              console.log(error.message)
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong! Please send your information and CV to warrence@codesquad.com.my',
                });
            }
        })
      }
    },    
  }
  
}
</script>

<style scoped>
.text-danger {
  color: pink !important;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>


