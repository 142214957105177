<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">AWS Solution Architect/DevOps Engineer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">AWS Engineer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>AWS Solution Architect/DevOps Engineer</span></h2>
                              <p>We are looking for a highly skilled and experienced AWS Solution Architect/DevOps Engineer to join our dynamic team. As an AWS Solution Architect/DevOps Engineer, you will play a crucial role in designing, implementing, and managing our cloud infrastructure and DevOps practices. Your expertise will be instrumental in ensuring the scalability, reliability, and security of our AWS-based applications and services. Join us and be part of an innovative and collaborative environment where your technical prowess can make a significant impact.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Collaborate with development teams and stakeholders to design and architect AWS-based solutions that align with business goals and technical requirements.</li>
                                  <li>Lead the planning, implementation, and migration of applications and workloads to AWS, ensuring best practices and cost optimization.</li>
                                  <li>Set up and configure AWS services, including EC2, S3, RDS, Lambda, API Gateway, etc., to support application requirements and performance.</li>
                                  <li>Develop and maintain CI/CD pipelines for automated code deployment, testing, and release processes using tools like Jenkins, GitLab CI/CD, or AWS CodePipeline.</li>
                                  <li>Implement infrastructure as code (IaC) using AWS CloudFormation or other IaC tools to achieve consistent and repeatable infrastructure deployments.</li>
                                  <li>Monitor AWS resources and applications, proactively identifying and resolving performance issues and security vulnerabilities.</li>
                                  <li>Design and implement security measures to safeguard AWS resources, including IAM policies, security groups, and encryption mechanisms.</li>
                                  <li>Collaborate with cross-functional teams to optimize application performance, scalability, and reliability in a cloud environment.</li>
                                  <li>Stay up-to-date with AWS services and DevOps trends, advocating for best practices and innovative solutions.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  Proven experience as an AWS Solution Architect or DevOps Engineer, with a track record of designing and managing cloud-based solutions.
                                  AWS certification (AWS Certified Solutions Architect, AWS Certified DevOps Engineer, or similar) is an advantage.
                                  In-depth knowledge of AWS services, including EC2, S3, RDS, VPC, IAM, Lambda, API
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="AWS Engineer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


