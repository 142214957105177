<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">UI/UX Designer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Designer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Freelance</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>UI/UX Designer</span></h2>
                              <p>We are searching for a talented and creative UI/UX Designer to join our innovative team. As a UI/UX Designer, you will be responsible for creating visually stunning and intuitive user interfaces for our digital products. Your design expertise will play a crucial role in crafting exceptional user experiences that resonate with our audience. Join us and be part of a collaborative environment where your designs can make a significant impact.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Collaborate with product managers and developers to understand project goals and user requirements, translating them into captivating UI/UX designs.</li>
                                  <li>Create wireframes, mockups, and prototypes to illustrate design concepts and interactions, iterating based on feedback and usability testing.</li>
                                  <li>Design user interfaces that align with brand guidelines, ensuring consistency and visual coherence throughout the application.</li>
                                  <li>Conduct user research and incorporate user feedback to optimize UI/UX designs for maximum usability and accessibility.</li>
                                  <li>Stay informed about industry trends and best practices, continuously improving design processes and advocating for user-centered design principles.</li>
                                  <li>Work closely with the development team to ensure seamless implementation of designs and maintain design integrity throughout development.</li>
                                  <li>Collaborate with stakeholders to align design decisions with business objectives and project timelines.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Proven experience as a UI/UX Designer, with an impressive portfolio showcasing your design projects and user-centric solutions.</li>
                                  <li>Proficiency in design tools like Adobe XD, Sketch, Figma, or similar to create wireframes, mockups, and prototypes.</li>
                                  <li>Solid understanding of user-centered design principles, information architecture, and usability best practices.</li>
                                  <li>A keen eye for aesthetics, typography, and visual details, with the ability to create visually appealing and cohesive designs.</li>
                                  <li>Experience in designing responsive interfaces for web and mobile applications, ensuring a seamless user experience across devices.</li>
                                  <li>Familiarity with HTML, CSS, and frontend development concepts to collaborate effectively with developers.</li>
                                  <li>Knowledge of interaction design, micro-interactions, and animation to enhance user engagement.</li>
                                  <li>Strong communication and collaboration skills, with the ability to present and defend design decisions.</li>
                                  <li>Experience with usability testing and user research methodologies to validate and iterate on design concepts.</li>
                                  <li>Ability to work in a fast-paced, iterative design process, welcoming feedback and incorporating changes to improve designs.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="UI/UX Designer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


