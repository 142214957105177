<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">IoT Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>IoT Developer</span></h2>
                              <p>We are seeking a talented and skilled IoT Developer with expertise in utilizing AWS services to build innovative Internet of Things (IoT) solutions. As an IoT Developer, you will play a crucial role in designing, developing, and deploying IoT applications that leverage the power of AWS IoT services. Join our team and contribute to the development of cutting-edge IoT solutions that empower our clients with data-driven insights and intelligent automation.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Collaborate with cross-functional teams to understand business requirements and design IoT solutions that cater to specific use cases and industry needs.</li>
                                  <li>Develop IoT applications using AWS IoT Core, AWS IoT Greengrass, and other relevant AWS services to enable seamless communication between devices and the cloud.</li>
                                  <li>Implement secure device communication and authentication mechanisms using AWS IoT Core security features.</li>
                                  <li>Design and develop AWS IoT Greengrass components to enable edge computing capabilities and real-time data processing at the device level.</li>
                                  <li>Integrate IoT devices with AWS cloud services, such as AWS Lambda, AWS DynamoDB, AWS S3, etc., for data storage, analytics, and management.</li>
                                  <li>Ensure IoT applications are scalable, reliable, and performant, even in resource-constrained IoT environments.</li>
                                  <li>Conduct thorough testing and validation of IoT solutions to ensure seamless integration and functionality.</li>
                                  <li>Stay up-to-date with the latest advancements in AWS IoT services and IoT technologies, and proactively propose improvements and optimizations.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Proven experience as an IoT Developer, with a focus on building IoT solutions using AWS services.</li>
                                  <li>In-depth knowledge of IoT concepts, protocols, and architectures, and hands-on experience with IoT devices and communication protocols (e.g., MQTT, CoAP).</li>
                                  <li>Expertise in AWS IoT services, such as AWS IoT Core, AWS IoT Greengrass, AWS Lambda, AWS DynamoDB, AWS S3, etc.</li>
                                  <li>Solid understanding of cloud computing principles and proficiency with other AWS services.</li>
                                  <li>Experience with secure device communication and authentication using AWS IoT Core security features, X.509 certificates, or AWS IoT Device Management.</li>
                                  <li>Strong programming skills, with proficiency in languages like Python, Node.js, or C/C++ for IoT application development.</li>
                                  <li>Knowledge of edge computing and experience with AWS IoT Greengrass for edge device management and real-time data processing.</li>
                                  <li>Familiarity with data analytics and machine learning concepts in the context of IoT applications is a plus.</li>
                                  <li>Strong problem-solving and analytical skills to address complex IoT challenges.</li>
                                  <li>Knowledge of version control systems like Git for collaborative development and code management.</li>
                                  <li>Good communication skills and the ability to work effectively in a team-oriented, agile development environment.</li>
                                  <li>Experience with IoT deployments at scale and knowledge of industrial IoT (IIoT) applications is an advantage.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="IoT Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


