import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // Customize scroll behavior
  scrollBehavior(to, from, savedPosition) {
    // If a saved position is available (e.g., back button), use it
    if (savedPosition) {
      return savedPosition;
    }

    // Scroll to the top of the page
    return { x: 0, y: 0 };
  },
})

export default router
