<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">Vue.js Frontend Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>Vue.js Frontend Developer</span></h2>
                              <p>We are seeking a talented and experienced Vue.js Frontend Developer to join our passionate team. As a Vue.js Frontend Developer, you will be responsible for designing and implementing user interfaces for our web applications using Vue.js framework. Your creativity and technical expertise will be instrumental in delivering visually stunning, responsive, and user-friendly experiences to our customers.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Collaborate with product managers and backend developers to translate design concepts into functional, interactive, and intuitive user interfaces using Vue.js.</li>
                                  <li>evelop reusable Vue components and libraries to streamline the frontend development process and enhance code maintainability.</li>
                                  <li>Implement responsive designs to ensure seamless user experiences across various devices and screen sizes.</li>
                                  <li>Optimize web applications for speed and performance, and conduct thorough testing to identify and resolve any issues.</li>
                                  <li>Stay up-to-date with the latest Vue.js trends, best practices, and emerging frontend technologies to drive continuous improvement.</li>
                                  <li>Work closely with UX/UI designers to maintain design consistency and visual coherence throughout the application.</li>
                                  <li>Participate in code reviews, provide constructive feedback, and collaborate with the team to ensure code quality and adherence to coding standards.</li>
                                  <li>Contribute to the development and improvement of frontend architecture and coding practices.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Bachelor's degree in Computer Science, Software Engineering, or a related field (or equivalent experience).</li>
                                  <li>Proven experience as a Frontend Developer with a strong focus on Vue.js development.</li>
                                  <li>Proficiency in JavaScript and Vue.js framework, including Vue components, directives, Vuex, and Vue Router.</li>
                                  <li>Familiarity with state management patterns and libraries, and the ability to design and implement effective data flow in Vue.js applications.</li>
                                  <li>Solid understanding of HTML, CSS, and responsive design principles, with an eye for detail in creating visually appealing interfaces.</li>
                                  <li>Experience with RESTful APIs and asynchronous data handling in Vue.js applications.</li>
                                  <li>Knowledge of modern frontend build tools and package managers such as Webpack, npm, or yarn.</li>
                                  <li>Strong debugging and problem-solving skills, with a passion for writing clean, efficient, and well-documented code.</li>
                                  <li>Familiarity with version control systems like Git for collaborative development and code management.</li>
                                  <li>Good communication skills and the ability to work effectively in a team-oriented, agile development environment.</li>
                                  <li>Experience with testing frameworks like Jest or Vue Test Utils is a plus.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="Vue.js Frontend Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


