<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">Lambda API Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>Lambda API Developer</span></h2>
                              <p>We are seeking a talented and enthusiastic Lambda API Developer to join our dynamic team. As a Lambda API Developer, you will be responsible for designing, developing, and maintaining serverless APIs using AWS Lambda. Your expertise will play a crucial role in building robust, scalable, and high-performance backend solutions for our cutting-edge web applications.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>Design and implement serverless APIs using AWS Lambda to handle incoming HTTP requests and responses.</li>
                                  <li>Collaborate with cross-functional teams to understand project requirements and develop API solutions that align with business objectives.</li>
                                  <li>Optimize API performance and ensure seamless integration with front-end applications for a smooth user experience.</li>
                                  <li>Work closely with DevOps and Cloud Engineers to set up and manage AWS services, such as API Gateway, IAM roles, and security configurations.</li>
                                  <li>Implement authentication and authorization mechanisms for secure API access.</li>
                                  <li>Conduct thorough testing, debugging, and troubleshooting of APIs to ensure reliability and functionality.</li>
                                  <li>Stay up-to-date with the latest trends and best practices in serverless architecture and AWS Lambda development.</li>
                                  <li>Stay up-to-date with the latest industry trends and best practices in backend development.</li>                               
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Proven experience in developing serverless APIs using AWS Lambda or similar serverless computing platforms.</li> 
                                  <li>Strong proficiency in programming languages like Node.js or Python for serverless function development.</li> 
                                  <li>Hands-on experience with AWS services, including API Gateway, IAM, and CloudWatch.</li> 
                                  <li>Familiarity with RESTful API design principles and best practices.</li> 
                                  <li>Knowledge of serverless deployment frameworks like AWS SAM or Serverless Framework.</li> 
                                  <li>Ability to work independently and in a team environment, with excellent communication and problem-solving skills.</li> 
                                  <li>Experience with version control systems, particularly Git, for code collaboration and management.</li> 
                                  <li>A strong commitment to writing clean, well-documented, and maintainable code.</li>
                                  <li>nowledge of front-end technologies (HTML, CSS, JavaScript) and full-stack development is a plus.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="Lambda API Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


