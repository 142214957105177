<template>
  <!-- Sidebar Menu start -->
    <div class="sidebar-wrapper">
        <div class="position-relative">
            <div class="side-menubar py-6 d-flex flex-column justify-content-between align-items-center">
                <div class="sidebar-close d-none d-xl-block">
                    <button class="d-center d-grid">
                        <i class="material-symbols-outlined mat-icon fs-three"> menu_open </i>
                        <span>MENU</span>
                    </button>
                </div>
                <ul class="d-grid gap-4 social-area">
                    <li>
                        <a :href="this.$store.state.fb" aria-label="Facebook" class="d-center">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a :href="this.$store.state.ig" aria-label="Instagram" class="d-center">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a :href="this.$store.state.linkedin" aria-label="Linkedin" class="d-center">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                    <li>
                        <a :href="this.$store.state.twitter" aria-label="Twitter" class="d-center">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                </ul>
                <div class="bottom-area">
                    
                </div>
            </div>
            <div class="sidebar-content d-center flex-columnn">
                <div class="header-section d-block">
                    <div class="navbar bg-transparent">
                        <ul class="navbar-nav d-xl-flex gap-2 gap-md-5 py-4 py-lg-0 px-4 px-lg-0 align-self-center">
                            <li class="dropdown show-dropdown">
                        <router-link to="/" exact><button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav fs-three heading">Home</button></router-link>
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                          class="dropdown-nav fs-three heading" @click="scrollToAnchor('whatWeDo')">What We Do</button>
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav fs-three heading" @click="scrollToAnchor('applications')">Our Projects</button>                        
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav fs-three heading" @click="scrollToAnchor('workspace')">Our Workspace</button>                        
                    </li>
                    <li class="dropdown show-dropdown">
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav fs-three heading" @click="scrollToAnchor('jobs')">Join Us</button>                        
                    </li>
                    <li>
                        <button type="button" aria-label="Navbar Dropdown Button"
                            class="dropdown-nav fs-three heading" @click="scrollToAnchor('contactUs')">Contact Us</button>
                    </li>
                        </ul>
                    </div>
                </div>
                <div class="footer-items w-100 bottom-0">
                    <div class="row">
                        <div class="col-xl-3 col-lg-6">
                            <div class="single-item p-5 py-xxl-10 px-xxl-8">
                                <h4 class="mb-6">Office</h4>
                                <div class="d-flex gap-3 align-items-center">
                                    <div class="icon-box d-center">
                                        <i class="material-symbols-outlined mat-icon fs-fure"> location_on </i>
                                    </div>
                                    <div class="right-item w-75">
                                        <h5>{{ this.$store.state.country }}</h5>
                                        <span>{{ this.$store.state.address }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="single-item p-5 py-xxl-10 px-xxl-8">
                                <h4 class="mb-6">Email address</h4>
                                <div class="d-flex gap-3 align-items-center">
                                    <div class="icon-box d-center">
                                        <i class="material-symbols-outlined mat-icon fs-fure"> mail </i>
                                    </div>
                                    <div class="right-item d-grid">
                                        <span>{{ this.$store.state.email }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="single-item p-5 py-xxl-10 px-xxl-8">
                                <h4 class="mb-6">Phone Number</h4>
                                <div class="d-flex gap-3 align-items-center">
                                    <div class="icon-box d-center">
                                        <i class="material-symbols-outlined mat-icon fs-fure"> call </i>
                                    </div>
                                    <div class="right-item d-grid">
                                        <span>{{ this.$store.state.phoneNumber }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="single-item p-5 py-xxl-10 px-xxl-8">
                                <h4 class="mb-6">Working Hours</h4>
                                <div class="d-flex gap-3 align-items-center">
                                    <div class="icon-box d-center">
                                        <i class="material-symbols-outlined mat-icon fs-fure"> schedule </i>
                                    </div>
                                    <div class="right-item w-50">
                                        <span>{{ this.$store.state.workingHours }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Sidebar Menu end -->
</template>

<script>
export default{
  name: 'SideBar',
  methods: {
    scrollToAnchor(anchorId) {
      this.$emit('scroll-to-anchor', anchorId);
    },
  },
}
</script>