import { render, staticRenderFns } from "./LaravelBackendDeveloper.vue?vue&type=template&id=e58ce2bc&scoped=true&"
import script from "./LaravelBackendDeveloper.vue?vue&type=script&lang=js&"
export * from "./LaravelBackendDeveloper.vue?vue&type=script&lang=js&"
import style0 from "./LaravelBackendDeveloper.vue?vue&type=style&index=0&id=e58ce2bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e58ce2bc",
  null
  
)

export default component.exports