<template>
  <div>
    <!-- Banner-section start -->
    <section class="banner-section index-two overflow-hidden">
        <div class="shape-area">
            <img src="assets/images/abs-items/bubble.png" class="shape-1" alt="icon">
            <img src="assets/images/abs-items/ellipse-7.png" class="shape-2" alt="icon">
        </div>
        <div class="overlay overflow-hidden">
            <div class="banner-content position-relative">
                <div class="box-items d-inline-flex flex-wrap position-absolute">
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-50"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item active-item opacity-25"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                    <div class="single-item"></div>
                </div>
                <div class="container position-relative cus-z1">
                    <div class="row justify-content-between justify-content-center align-items-center">
                        <div class="col-xl-6 col-lg-7">
                            <div class="main-content">
                                <div class="mb-8">
                                    <h3 class="visible-slowly-bottom sub-title"><span>crafting Exceptional Application</span></h3>
                                    <span class="display-one">Elevating Application to the <span>Next
                                            Level</span></span>
                                    <p class="fs-four">We are a software development company that focuses on creating imaginative, innovative, and vibrant solutions for our clients.</p>
                                </div>
                                <div class="btn-area alt-bg">
                                    <a class="box-style btn-box d-center" @click="scrollToSection('applications')">
                                        Explore Our App
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-20">
                        
                        </div>
                        <div class="col-md-6">
                            
                        </div>
                    </div>
                </div>
            </div>
    </section>
    <!-- Banner-section end -->
    

    <!-- Our Services start -->
    <section id="whatWeDo" class="our-services index-two index-three pt-120 pb-120">
        <div class="container position-relative">
            <div class="shape-area d-none d-lg-block position-absolute">
                <img src="assets/images/abs-items/star.png" alt="Icon">
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-5">
                    <div class="section-header">
                        <h4 class="sub-title">Bringing Your <span>Application</span> to Life</h4>
                        <span class="fs-two heading mb-6">Innovative <span>Application Solutions</span>
                            Everywhere</span>
                        <p>Our team of visionary developers, designers, and technologists thrives on the challenge of turning ideas into reality. With a deep understanding of the latest technologies and industry trends, we transform concepts into vibrant and intuitive applications that captivate audiences across platforms.</p>
                    </div>
                    <div class="services-thumb d-inline-block position-relative">
                        <div class="thumb">
                            <img src="assets/images/services-thumb.png" alt="Image">
                        </div>                        
                    </div>
                </div>
                <div class="col-lg-6 mt-10 mt-lg-0">
                    <div class="cus-mar">
                        <div
                            class="single-box box-style box-second py-5 py-sm-8 d-flex gap-4 gap-md-6 justify-content-between align-items-center">
                            <div class="content-box">
                                <a >
                                    <h3 class="visible-slowly-bottom mb-3">Backend Development</h3>
                                </a>
                                <p>System Analysis, System Design, Database Design, Server-Side(Backend) Programming, RESTful API,
                                    Data Handling, Backend Testing, API Integration.</p>
                            </div>
                            
                        </div>
                        <div
                            class="single-box box-style box-second py-5 py-sm-8 d-flex gap-4 gap-md-6 justify-content-between align-items-center">
                            <div class="content-box">
                                <a >
                                    <h3 class="visible-slowly-bottom mb-3">Frontend Development</h3>
                                </a>
                                <p>User Interface (UI) Design, User Experience (UX) Design, Frontend Programming, Responsive and Mobile-Friendly Design, Cross-Browser Compatibility, Performance Optimization.</p>
                            </div>
                            
                        </div>
                        <div
                            class="single-box box-style box-second py-5 py-sm-8 d-flex gap-4 gap-md-6 justify-content-between align-items-center">
                            <div class="content-box">
                                <a >
                                    <h3 class="visible-slowly-bottom mb-3">Mobile App Development</h3>
                                </a>
                                <p>App Design, App Prototyping, Mobile App Programming, Cross-Platform Compatibility, API Integration, App Analytics, User Insights</p>
                            </div>
                            
                        </div>
                        <div
                            class="single-box box-style box-second py-5 py-sm-8 d-flex gap-4 gap-md-6 justify-content-between align-items-center">
                            <div class="content-box">
                                <a >
                                    <h3 class="visible-slowly-bottom mb-3">Cloud Solution Architecture</h3>
                                </a>
                                <p>Cloud Infrastructure Design, Cloud Application Design, Cloud Security and Compliance, Cloud Integration Services, Cloud Migration and Deployment, Cloud Cost Optimization, Cloud Monitoring and Management</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Our Services end -->

    <!-- Recently Completed start -->
    <section id="applications" class="recently-completed index-two">
        <div class="container pt-120 pb-120">
            <div class="row section-header justify-content-between align-items-center">
                <div class="col-lg-8">
                    <h4 class="sub-title">Our Current <span>Projects</span></h4>
                    <span class="fs-two heading mb-6">Where Innovation Meets <span>Software Development and Artistry</span></span>
                </div>
                
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <div class="recently-completed-carousel">
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-3.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a>
                                            <h3 class="visible-slowly-bottom mb-3">BorneoParkKCH</h3>
                                        </a>
                                        <p>Effortless Street Seasonal Parking in Kuching: Our mobile app ensures convenient seasonal parking and compound payment options for a seamless experience anytime, anywhere.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-1.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a>
                                            <h3 class="visible-slowly-bottom mb-3">BorneoParkMIRI</h3>
                                        </a>
                                        <p>Effortless Street Parking in Miri: Our mobile app ensures stress-free street parking with cashless payments, convenient seasonal parking and compound payment options for a seamless experience anytime, anywhere.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-2.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">BorneoParkBTU</h3>
                                        </a>
                                        <p>Effortless Street Parking in Bintulu: Our mobile app ensures stress-free street parking with cashless payments and convenient seasonal parking for a seamless experience anytime, anywhere.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-4.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">STSS e-Paper</h3>
                                        </a>
                                        <p>Sarawak’s oldest newspaper New Sarawak Tribune and youngest newspaper Suara Sarawak bring you current news that are Sarawak-centric, national and global news that have relevance to Sarawak in English, Malay and Iban languages where ever you are, conveniently in one App.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-5.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">CSPark Office</h3>
                                        </a>
                                        <p>Streamline Admin Portal: Empowering efficient parking and compound management with user-friendly features. Seamlessly handle seasonal parking, coupons, and more for a hassle-free experience.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-6.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">CSFR</h3>
                                        </a>
                                        <p>Efficient Council Admin Portal: Issue final reminders and letters of demand for outstanding parking compounds. Streamline processes with user-friendly tools for prompt resolution.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-7.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">Sonido</h3>
                                        </a>
                                        <p>Sonido is a recording studio management software that makes the daily management of your business more simplified, efficient and profitable. From managing leads and projects, easy scheduling, effective management of projects, secure file review and delivery – and everything in between – managing your studio business is about to become much easier.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-area">
                            <div class="single-slider position-relative d-grid align-items-center">
                                <div class="img-box">
                                    <img src="assets/images/recently-completed-img-8.png" alt="Image">
                                </div>
                                <div class="position-absolute cus-position start-0">
                                    <div class="content-box p-3 p-sm-8">
                                        <a href="game.html">
                                            <h3 class="visible-slowly-bottom mb-3">AWS Infrastructure</h3>
                                        </a>
                                        <p>We excel in providing comprehensive AWS infrastructure services, encompassing meticulous planning, expert design, flawless deployment, continuous monitoring, robust security compliance, and proactive management across multiple customer AWS accounts, ensuring unparalleled cloud performance for our valued clients.
                                        </p>
                                        <!-- <a class="end-area mt-8 d-center">
                                            <i class="material-symbols-outlined"> call_made </i>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Recently Completed end -->

    <!-- Counter Section start -->
    <div class="counter-section index-two pb-120 pt-120">
        <div class="container">
            <div class="row cus-mar">
                <div class="col-lg-4 col-md-6">
                    <div class="single-box text-center py-6 box-style box-first">
                        <div class="icon-box d-center m-auto">
                            <i class="material-symbols-outlined"> rocket_launch </i>
                        </div>
                        <div class="counters pt-6 pb-3 d-center">
                            <span class="odometer fs-two heading-white" :data-odometer-final="yearInBusiness">0</span>
                            <span class="fs-two">+</span>
                        </div>
                        <p class="name-area fs-six">
                            Years In Business
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-box text-center py-6 box-style box-first">
                        <div class="icon-box d-center m-auto">
                            <i class="material-symbols-outlined"> download </i>
                        </div>
                        <div class="counters pt-6 pb-3 d-center">
                            <span class="odometer fs-two heading-white" data-odometer-final="200000">0</span>
                            <span class="fs-two ms-3">+</span>
                        </div>
                        <p class="name-area fs-six">
                            Our App Downloads in iOS and Android
                        </p>
                    </div>
                </div>                
                <div class="col-lg-4 col-md-6">
                    <div class="single-box text-center py-6 box-style box-first">
                        <div class="icon-box d-center m-auto">
                            <i class="material-symbols-outlined"> group_add </i>
                        </div>
                        <div class="counters pt-6 pb-3 d-center">
                            <span class="odometer fs-two heading-white" data-odometer-final="100000">0</span>
                            <span class="fs-two ms-3">+</span>
                        </div>
                        <p class="name-area fs-six">
                            Our App Monthly active users
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Counter Section end -->

    <!-- our partners start -->
    <section id="clients" class="our-partners pt-120 pb-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-header text-center">
                        <h4 class="sub-title"><span>Trusted</span> By</h4>
                        <span class="fs-two heading mb-6">We are happy to work with <span>the leading companies</span></span>
                        <p>We are delighted to collaborate with leading industry partners.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="all-items mb-7 d-flex gap-3 justify-content-center flex-wrap">
                        <div class="thumb">
                            <img src="assets/images/client_logos/bps.png" alt="Image">
                        </div>
                        <div class="thumb">
                            <img src="assets/images/client_logos/kcps.png" alt="Image">
                        </div>
                        <div class="thumb">
                            <img src="assets/images/client_logos/bdap.png" alt="Image">
                        </div>
                        <div class="thumb">
                            <img src="assets/images/client_logos/mbks.png" alt="Image">
                        </div>
                    </div>
                    <div class="all-items d-flex gap-3 justify-content-center flex-wrap">
                        <div class="thumb">
                            <img src="assets/images/client_logos/mcc.png" alt="Image">
                        </div>                        
                        <div class="thumb">
                            <img src="assets/images/client_logos/sonido.png" alt="Image">
                        </div>
                        <div class="thumb">
                            <img src="assets/images/client_logos/stss.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- our partners end -->

    <!-- Gallery Studio start -->
    <section id="workspace" class="gallery-studio pt-120 pb-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="section-header text-center">
                        <h4 class="sub-title">Day in the Life of <span>Our Coding Workspace</span></h4>
                        <span class="fs-two heading mb-6">The pulse of Programming Innovation: A <span>Gallery
                                of Our Workspace</span></span>
                        <p>Join us for an exclusive glimpse into our programming workspace. Explore the creativity, cutting-edge technology, and innovation behind each remarkable project.</p>
                    </div>
                </div>
            </div>
            <div class="row cus-mar">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-box d-grid gap-3 gap-md-6">
                        <div class="items">
                            <img src="assets/images/gallery-img-1.png" alt="image">
                        </div>
                        <div class="inside-items d-flex gap-3 gap-md-6">
                            <div class="items">
                                <img src="assets/images/gallery-img-2.png" alt="image">
                            </div>
                            <div class="items">
                                <img src="assets/images/gallery-img-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-box d-grid gap-3 gap-md-6">
                        <div class="inside-items d-flex gap-3 gap-md-6">
                            <div class="items">
                                <img src="assets/images/gallery-img-4.png" alt="image">
                            </div>
                            <div class="items">
                                <img src="assets/images/gallery-img-5.png" alt="image">
                            </div>
                        </div>
                        <div class="items">
                            <img src="assets/images/gallery-img-6.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-box d-grid gap-3 gap-md-6">
                        <div class="items">
                            <img src="assets/images/gallery-img-7.png" alt="image">
                        </div>
                        <div class="inside-items d-flex gap-3 gap-md-6">
                            <div class="items">
                                <img src="assets/images/gallery-img-8.png" alt="image">
                            </div>
                            <div class="items">
                                <img src="assets/images/gallery-img-9.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Gallery Studio end -->

    <!-- Job Opens start -->
    <section id="jobs" class="job-opens index-two pt-120 pb-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="section-text text-center">
                        <h4 class="sub-title">We're Looking for <span>Talented Professionals</span></h4>
                        <span class="fs-two heading mb-6 w-75 m-auto"><span>Let's Craft the Future</span> of
                            CODING Together!</span>
                        <p>Our belief is that being simple, honest, self-driven and motivated combined with the
                            constant pursuit of the ultimate company atmosphere can help grow a creative and
                            robust team.</p>
                    </div>
                </div>
            </div>
            <div class="row cus-mar">
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> data_object </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>
                                </ul>
                                <a @click="showJobDetails('laravelBackendDeveloper')">
                                    <h5 class="sub-title"><span>Laravel Backend Developer</span></h5>                                    
                                </a>
                            </div>
                        </div>
                        <a class="end-area d-center" @click="showJobDetails('laravelBackendDeveloper')">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> data_object </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>
                                </ul>
                                <a @click="showJobDetails('lambdaApiDeveloper')">
                                    <h5 class="sub-title"><span>Lambda API Developer</span></h5>                                    
                                </a>
                            </div>
                        </div>
                        <a class="end-area d-center" @click="showJobDetails('lambdaApiDeveloper')">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> code </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>
                                </ul>
                                <a @click="showJobDetails('vuejsFrontendDeveloper')">
                                    <h5 class="sub-title"><span>Vue.js Frontend Developer</span></h5>                                    
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('vuejsFrontendDeveloper')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> developer_mode </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>                                    
                                </ul>
                                <a @click="showJobDetails('mobileAppDeveloper')">
                                    <h5 class="sub-title"><span>Mobile App Developer</span></h5>
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('mobileAppDeveloper')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> psychology </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>
                                </ul>
                                <a @click="showJobDetails('aiDeveloper')">
                                    <h5 class="sub-title"><span>AI Developer</span></h5>
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('aiDeveloper')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> settings_remote </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Developer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full/Part Time</span>
                                    </li>                                    
                                </ul>
                                <a @click="showJobDetails('iotDeveloper')">
                                    <h5 class="sub-title"><span>IoT Developer</span></h5>                                    
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('iotDeveloper')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> draw </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">Designer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Freelance</span>
                                    </li>
                                </ul>
                                <a @click="showJobDetails('uiuxDesigner')">
                                    <h5 class="sub-title"><span>UI/UX Designer</span></h5>
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('uiuxDesigner')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="single-box flex-wrap box-style box-second p-3 p-md-6 d-center gap-4 gap-md-6 justify-content-between">
                        <div class="content-box d-flex flex-wrap gap-4 gap-md-6">
                            <div class="icon-box d-inline-flex d-center">
                                <i class="material-symbols-outlined fs-three"> cloud </i>
                            </div>
                            <div class="info-box">
                                <ul class="d-flex flex-wrap gap-3 gap-sm-6 mb-5">
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> work </i>
                                        <span class="fs-seven">AWS Engineer</span>
                                    </li>
                                    <li class="d-flex align-items-center gap-2">
                                        <i class="material-symbols-outlined mat-icon"> location_on </i>
                                        <span class="fs-seven">Full Time</span>
                                    </li>                                    
                                </ul>
                                <a @click="showJobDetails('awsEngineer')">
                                    <h5 class="sub-title"><span>AWS Engineer</span></h5>
                                </a>
                            </div>
                        </div>
                        <a @click="showJobDetails('awsEngineer')" class="end-area d-center">
                            <i class="material-symbols-outlined"> call_made </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Job Opens end -->

    <!-- Contact Us start -->
    <section id="contactUs" class="contact-us pb-120">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-5">
                    <div class="section-text">
                        <h4 class="sub-title">Have <span>Questions?</span></h4>
                        <span class="fs-two heading mb-6">We'd Love To <span>Hear From You</span></span>
                        <p>Please fill out the form and let us know about your concerns.We will try our best to
                            provide optimized solutions.</p>
                    </div>
                    <div class="contact-area">
                        <div class="btn-area mt-10 d-grid gap-6 align-items-center">
                            <div class="d-flex gap-3 align-items-center">
                                <div class="icon-box d-center">
                                    <i class="material-symbols-outlined mat-icon fs-five"> call </i>
                                </div>
                                <span>{{ this.$store.state.phoneNumber }}</span>
                            </div>
                            <div class="d-flex gap-3 align-items-center">
                                <div class="icon-box d-center">
                                    <i class="material-symbols-outlined mat-icon fs-five"> mail </i>
                                </div>
                                <span>{{ this.$store.state.email}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 me-20 mt-7 mt-lg-20">
                        <div class="form-inside p-4">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-input text-start">
                                        <label for="contactName">Name</label>
                                        <input type="text" id="contactName" placeholder="Enter Your Name"
                                            autocomplete="off" v-model="feedback.name">
                                        <div v-if="$v.feedback.name.$dirty  && !$v.feedback.name.required"  class="text-danger">Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="single-input text-start">
                                        <label for="contactEmail">Email</label>
                                        <input type="email" id="contactEmail" placeholder="Enter your email"
                                            autocomplete="off" v-model="feedback.email">
                                        <div v-if="$v.feedback.email.$dirty  && !$v.feedback.email.required"  class="text-danger">Email is required.</div>
                                        <div v-else-if="$v.feedback.email.$dirty  && !$v.feedback.email.email"  class="text-danger">Invalid email format.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="single-input text-start">
                                        <label for="contactSubject">Subject</label>
                                        <input type="text" id="contactSubject" placeholder="Enter Subject"
                                            autocomplete="off" v-model="feedback.subject">
                                        <div v-if="$v.feedback.subject.$dirty  && !$v.feedback.subject.required"  class="text-danger">Subject is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="single-input text-start">
                                        <label for="contactMessage">Leave us a message</label>
                                        <textarea cols="4" rows="4" id="contactMessage"
                                            placeholder="Please type your Message here..." v-model="feedback.message"></textarea>
                                        <div v-if="$v.feedback.message.$dirty  && !$v.feedback.message.required" class="text-danger">Message is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mt-4">
                                    <div class="btn-area">
                                        <button class="box-style btn-box" :disabled="isLoading" @click="submitFeedback()">
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm text-center" role="status" aria-hidden="true"></span>
                                        Send Massage
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Us end -->

    <laravel-backend-developer ref="laravelBackendDeveloper" />
    <lambda-api-developer ref="lambdaApiDeveloper" />
    <vuejs-frontend-developer ref="vuejsFrontendDeveloper" />
    <mobile-app-developer ref="mobileAppDeveloper" />
    <ai-developer ref="aiDeveloper" />
    <iot-developer ref="iotDeveloper" />
    <UIUXDesigner ref="uiuxDesigner" />
    <AWSEngineer ref="awsEngineer" />
    
  </div>
</template>



<script>
import moment from 'moment';
import LaravelBackendDeveloper from './jobs/LaravelBackendDeveloper.vue';
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import Swal from 'sweetalert2';
import LambdaApiDeveloper from './jobs/LambdaApiDeveloper.vue';
import VuejsFrontendDeveloper from './jobs/VuejsFrontendDeveloper.vue';
import MobileAppDeveloper from './jobs/MobileAppDeveloper.vue';
import AiDeveloper from './jobs/AiDeveloper.vue';
import IotDeveloper from './jobs/IotDeveloper.vue';
import UIUXDesigner from './jobs/UIUXDesigner.vue';
import AWSEngineer from './jobs/AWSEngineer.vue';

export default {
  name: 'HomeView',
  props: ['anchorId'],
  components: {
    LaravelBackendDeveloper,
    LambdaApiDeveloper,
    VuejsFrontendDeveloper,
    MobileAppDeveloper,
    AiDeveloper,
    IotDeveloper,
    UIUXDesigner,
    AWSEngineer 
  },
  mounted(){ 
    if(this.anchorId){
      this.scrollToSection(this.anchorId)
    }
  },
  validations: {
    feedback: {
      name: { required },
      email: { required, email },
      subject: { required },
      message: { required },
    },
    form: ['feedback']
  },
  data: () => ({
    isLoading: false,
    yearInBusiness: moment().diff('1994-01-01', 'years', false),
    selectedJobs: null,
    feedback: {
      name: '',
      email: null,
      subject: null,
      message: null
    }
  }),
  methods: {
    showJobDetails(job){      
      console.log(job)
      this.$refs[job].show();
    },  
    scrollToSection(sectionId) {
      
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    submitFeedback(){
      this.$v.form.$touch()
      if (!this.$v.feedback.$invalid) {
        this.isLoading = true;
        
        axios.post(' https://1wp937iozg.execute-api.ap-southeast-1.amazonaws.com/default/codesquadWebSendEmailFunction', 
        this.feedback, { 
          withCredentials: false,
        })
        .then(response => {
          this.isLoading = false
           // Show success message
           if(response.status == 200){
              Swal.fire({
                icon: 'success',
                title: 'Thank You!',
                text: 'Your feedback has been submitted successfully.',
                timer: 3000, // Auto close after 3 seconds
                timerProgressBar: true, // Show progress bar for timer
                showConfirmButton: false, // Hide the "OK" button
              });
           }           
        }).catch(error =>{
          this.isLoading = false
          if(error){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Please try again later.',
              });
          }
        })
      }
    }
  }
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: none; /* Remove default numbering */
  }

  .custom-list li::before {
    content: "- "; /* Use the hyphen as the list marker */
  }

  .text-danger {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
}
</style>


