<template>
  <div>
  <!-- Footer Area Start -->
    <footer class="footer-section index-two">
        <div class="container pt-15">
            <div class="row footer-wrapper px-4 px-sm-6 pt-120 pb-120">
                <div class="col-xl-4 col-md-5">
                    <div class="single-box visible-from-bottom">
                        <a href="index.html">
                            <img src="assets/images/logo-text.png" class="logo" alt="logo">
                        </a>
                        <p class="mt-4 mb-6">Codesquad is a leading software development company crafting innovative and engaging applications for users of all backgrounds. Offering a diverse range of programming experiences.</p>
                        <ul class="d-flex gap-4 social-area">
                            <li>
                                <a :href="this.$store.state.fb" aria-label="Facebook" class="d-center">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a :href="this.$store.state.ig" aria-label="Instagram" class="d-center">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a :href="this.$store.state.linkedin" aria-label="Linkedin" class="d-center">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li>
                                <a :href="this.$store.state.twitter" aria-label="Twitter" class="d-center">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-md-2 col-sm-3">
                    <!-- <div class="single-box links visible-from-bottom">
                        <h4 class="mb-4">Links</h4>
                        <ul class="items d-grid gap-4 ms-4">
                            <li><a href="index-3.html" class="d-flex align-items-center">Home</a></li>
                            <li><a href="shop-details-2.html" class="d-flex align-items-center">Services</a>
                            </li>
                            <li><a href="game.html" class="d-flex align-items-center">Games</a></li>
                            <li><a href="blog.html" class="d-flex align-items-center">Blog</a></li>
                            <li><a href="contact.html" class="d-flex align-items-center">Contact</a></li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-xl-3 col-md-5 col-sm-8">
                    <div class="single-box visible-from-bottom">
                        <h4 class="mb-4">Office</h4>
                        <div class="contact-location d-grid gap-6">
                            <div class="single-area d-flex gap-6">
                                <div class="icon-area d-center">
                                    <i class="material-symbols-outlined"> location_on </i>
                                </div>
                                <div class="text-area">
                                    <h5 class="mb-2">{{ this.$store.state.country }}</h5>
                                    <span>{{ this.$store.state.address }}</span>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-7">
                    <div class="single-box visible-from-bottom d-center">
                        <img src="assets/images/codesquad-logo.png" class="footer-logo" alt="logo">
                    </div>
                </div>
            </div>
            <div class="footer-bottom bg-transparent pt-6 pb-8">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6 text-center">
                            <div>
                                <p>Copyright © 1994-{{ yearNow }} <a href="index.html">{{ this.$store.state.companyName }}</a> - All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End -->
    </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PageFooter',
  computed: {
    yearNow(){ 
      return moment().format('YYYY')
    }
  }  
}
</script>