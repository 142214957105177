<template>
  <div>
    <b-modal v-if="showModal" 
      v-model="showModal" 
      ref="careerDetailsModal" 
      @show="onShow()" 
      size="xl" 
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      header-border-variant="dark"
      footer-border-variant="dark"
      ok-only
      ok-title="Close"
      ok-variant="primary"
      scrollable    
    >
        <!--
      you can use custom content here to overwrite
      default content
    -->
    <div>
      <!-- Job Opens start -->
      <section class="job-opens details ">
          <div class="container">
              <div class="row justify-content-between">
                  <div class="col-xl-7 col-lg-7">
                      <div class="content-wrapper d-grid gap-4 gap-sm-8">
                          <div class="d-flex gap-6 align-items-center row">
                              <div class="end-area col-12">
                                  <span class="fs-seven p-1 px-2">Mobile App Developer</span>
                              </div>
                              <ul class="d-flex gap-6 col-12">
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> work </i>
                                      <span class="fs-seven">Developer</span>
                                  </li>
                                  <li class="d-flex align-items-center gap-2">
                                      <i class="material-symbols-outlined mat-icon"> schedule </i>
                                      <span class="fs-seven">Full Time / Part Time</span>
                                  </li>
                              </ul>
                          </div>
                          <div class="single-content mb-8">
                              <h2 class="visible-slowly-bottom display-four mb-2"><span>Mobile App Developer</span></h2>
                              <p>We are seeking a talented and innovative Mobile App Developer with expertise in writing cross-platform applications using Vue.js or React Native. As a Mobile App Developer, you will play a key role in designing and building user-friendly mobile apps that run seamlessly on both iOS and Android platforms. Join our dynamic team and contribute to the creation of cutting-edge mobile experiences for our users.</p>
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Responbilities</span></h3>
                              <ul class="custom-list">
                                  <li>ollaborate with product managers, UI/UX designers, and backend developers to conceptualize, design, and implement cross-platform mobile applications.</li>
                                  <li>Develop responsive and intuitive user interfaces using Vue.js or React Native, ensuring a consistent experience across devices.</li>
                                  <li>Write clean, maintainable, and high-performance code, and conduct thorough testing to ensure app reliability and functionality.</li>
                                  <li>Integrate mobile apps with backend APIs and web services to enable seamless data exchange and synchronization.</li>
                                  <li>Optimize app performance and responsiveness to deliver smooth user experiences on various devices and screen sizes.</li>
                                  <li>Stay up-to-date with the latest trends and advancements in mobile app development, cross-platform frameworks, and emerging technologies.</li>
                                  <li>Collaborate with the team to identify and resolve technical issues, bugs, and other challenges in the development process.</li>
                                  <li>Participate in code reviews, provide constructive feedback, and maintain code quality and best practices.</li>
                              </ul>                              
                          </div>
                          <div class="single-content">
                              <h3 class="visible-slowly-bottom mb-3"><span>Requirements</span></h3>
                              <ul class="custom-list">
                                  <li>Proven experience as a Mobile App Developer, with a focus on cross-platform development using Vue.js and/or React Native.</li>
                                  <li>Proficiency in Vue.js or React Native, along with a solid understanding of JavaScript and modern frontend development practices.</li>
                                  <li>Familiarity with state management patterns and libraries (e.g., Vuex, Redux) for efficient data flow in cross-platform applications.</li>
                                  <li>Experience in building and deploying mobile apps on both iOS and Android platforms.</li>
                                  <li>Knowledge of mobile app design principles, responsive layouts, and mobile-specific UI/UX considerations.</li>
                                  <li>Familiarity with RESTful APIs and asynchronous data handling in mobile app development.</li>
                                  <li>Strong debugging and problem-solving skills, with a passion for writing clean, well-documented, and modular code.</li>
                                  <li>Understanding of version control systems like Git for collaborative development and code management.</li>
                                  <li>Good communication skills and the ability to work effectively in a team-oriented, agile development environment.</li>
                                  <li>Experience with testing frameworks for mobile app testing is a plus.</li>
                                  <li>Knowledge of other cross-platform frameworks (e.g., Xamarin, Flutter) and full-stack development is an advantage.</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-5 col-md-7 mt-8 mt-lg-0 alt-bg">
                      <apply-now job="Mobile App Developer" />
                  </div>
              </div>
          </div>
      </section>
    </div>
    <!-- Job Opens end -->
      </b-modal>        
  </div>
</template>

<script>
import ApplyNow from './ApplyNow.vue'


export default {
  components: { ApplyNow },
  name: 'LambdaApiDeveloper',   
  mounted(){    
    
  },
  data:() => ({
    showModal: false,
  }),
  methods:{
    applyNow(){

    },
    show(){
      this.showModal = true
    },
    onShow(){
    },
    hide(){
      this.showModal = false
    }
  }
  
}
</script>

<style scoped>
/* Add some basic styles to anchor links */
a {
  cursor: pointer;
}

/* Assuming you have a class or ID named "custom-list" */
  .custom-list {
    list-style-type: circle; /* Remove default numbering */
  }

  .custom-list li::marker {
    color: var(--p300); /* Use the hyphen as the list marker */
  }

  
</style>


